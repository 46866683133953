import React, { useEffect, useRef, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import logoImg from '@asset/images/logo_h.jpg';
import classnames from 'classnames';
import { useClientWidthType } from '@hooks/useClientWidthType';

import './header.scss';



interface IProps {
}

interface INavItem {
  id: string;
  name: string;
  path: string;
}

interface INavJsonData {
  allNavJson: {
    nodes: INavItem[];
  }
}

const Header: React.FC<IProps> = props => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { allNavJson: { nodes: navList } } = useStaticQuery<INavJsonData>(graphql`
    query navListQuery {
      allNavJson (
        filter:{ hidden: { ne: true } }
      ) {
        nodes {
          id
          name
          path
        }
      }
    }
  `);

  const { isMobile } = useClientWidthType();

  const navElmRef = useRef<HTMLElement>(null);

  const animationEnd = () => {
    const elm = navElmRef.current;
    if (!elm) {
      return;
    }
    if (elm.classList.contains('nav-out')) {
      elm.style.display = 'none';
    }
  }

  const handleIsMobileChange = () => {
    const elm = navElmRef.current;
    if (!elm) return;
      elm.style.display = 'none';
    if (isMobile) {
    } else {
      setShowMobileMenu(false);
      elm.style.display = 'flex';
    }
  }

  const handleShowMobileMenuChange = () => {
    if (showMobileMenu) {
      const elm = navElmRef.current;
      if (!elm) return;
      elm.style.display = 'flex';
    }
  }

  useEffect(() => {
    handleShowMobileMenuChange();
  }, [showMobileMenu]);

  useEffect(() => {
    handleIsMobileChange();
  }, [isMobile]);

  return (
    <header className="header-wrapper">
      <div className="logo-wrapper">
        <Link to="/">
          <img className="logo-img" src={logoImg} alt="久久联创" />
        </Link>
        <h1 className="txt">久久联创</h1>
      </div>
      <nav
        className={classnames('nav-wrapper', {
          'nav-in': showMobileMenu,
          'nav-out': !showMobileMenu,
        })}
        ref={navElmRef}
        onAnimationEnd={animationEnd}
      >
        {navList.map(item => {
          return <Link
          className="nav-item"
          to={item.path}
          key={item.path}
          onClick={() => setShowMobileMenu(false)}
        >{item.name}</Link>
        })}
      </nav>
      <i className="mobile-menu-btn iconfont icon-menu" onClick={() => setShowMobileMenu(!showMobileMenu)} />
    </header>
  )
}

export default Header;
