export enum WidthTypeEnum {
  XXL = 'xxl',
  XL = 'xl',
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XS = 'xs',
}

export const headerHeightMap: Record<WidthTypeEnum, number> = {
  [WidthTypeEnum.XXL]: 80,
  [WidthTypeEnum.XL]: 75,
  [WidthTypeEnum.LG]: 65,
  [WidthTypeEnum.MD]: 60,
  [WidthTypeEnum.SM]: 60,
  [WidthTypeEnum.XS]: 60,
}
