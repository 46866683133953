import React, { useEffect } from 'react';
import Dialog from 'rc-dialog';
import { useCookieState } from 'ahooks';
import { graphql, useStaticQuery } from 'gatsby';

import 'rc-dialog/assets/index.css';
import './confirm.scss';

interface IProps {}

interface notificationQueryData {
  file: {
    childMarkdownRemark: {
      html: string;
    }
  }
}

const ConfirmDialog: React.FC<IProps> = props => {
  const [cookie, setCookie] = useCookieState(
    'confirm-private-fund-notification',
    {
      // 过期时间为1天
      expires: 1,
      // secure: true,
    }
  );
  const { file: { childMarkdownRemark: { html } } } = useStaticQuery<notificationQueryData>(graphql`
    {
      file (name: { eq: "privateFundNotification" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);

  const assept = () => {
    setCookie('1');
  };

  const closeWebsice = () => {
    location.href = 'about:blank';
    window.close();
  };

  return (
    <Dialog
      visible={!cookie}
      closable={false}
      footer={
        <>
          <button className="btn assept-btn" onClick={assept}>接受并继续访问</button>
          <button className="btn reject-btn" onClick={closeWebsice}>不同意并放弃</button>
        </>
      }
      // title="重要提示"
      className="confirm-dialog"
    >
      <div className="title">重要提示</div>
      <article className="notification" dangerouslySetInnerHTML={{ __html: html }} />
    </Dialog>
  );
};

export default ConfirmDialog;
