import React from 'react';
import Layout from './src/components/layout';

import './src/asset/iconfont/iconfont.css';
import './src/asset/scss/base.scss';
import './src/asset/scss/reset.scss';
import './src/asset/scss/common.scss';

// console.log('envfofofo', process.env.NODE_ENV);
// import VConsole from 'vconsole';
// new VConsole({ theme: 'dark' });

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
