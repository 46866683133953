import React from 'react';
import Header from '@components/header';
import Footer from '@components/footer';
import ConfirmDialog from '@components/confirm';
import './layout.scss';

// 引入全局样式文件
interface IProps {
  children?: any;
}

const Layout: React.FC<IProps> = props => {

  return (
    <>
      <ConfirmDialog  />
      <Header />
      <main className="layout-main">{props.children}</main>
      <Footer />
    </>
  )
}

export default Layout;
