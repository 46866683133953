import React from 'react';
import './footer.scss';
import { graphql, useStaticQuery } from 'gatsby';

interface IProps {
}

interface FooterJsonData {
  dataJson: {
    copyright: string;
    filling: string;
  }
}

const Footer: React.FC<IProps> = props => {
  const { dataJson: { copyright, filling } } = useStaticQuery<FooterJsonData>(graphql`
    query footerQuery {
      dataJson (
        jsonType: { eq: "footer" }
      ) {
        filling
        copyright
      }
    }
  `);

  return (
    <footer className="footer-wrapper">
      <a href="https://beian.miit.gov.cn" target="_blank">{copyright}</a>
      <a href="https://beian.miit.gov.cn" target="_blank">{filling}</a>
    </footer>
  )
}

export default Footer;
