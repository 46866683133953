import { WidthTypeEnum } from '@asset/config/layout';
import { useEventListener } from 'ahooks'
import { useEffect, useState } from 'react';

const minWidthMap: Record<WidthTypeEnum, number> = {
  [WidthTypeEnum.XXL]: 1600,
  [WidthTypeEnum.XL]: 1200,
  [WidthTypeEnum.LG]: 992,
  [WidthTypeEnum.MD]: 768,
  [WidthTypeEnum.SM]: 576,
  [WidthTypeEnum.XS]: 0,
}

// 获取客户端宽度类型
export function useClientWidthType () {
  const [type, setType] = useState<WidthTypeEnum>(WidthTypeEnum.XXL);
  const [isMobile, setIsMobile] = useState<boolean>(true);

  const updateWidthType = () => {
    const width = window.document.body.clientWidth;
    if (width >= minWidthMap[WidthTypeEnum.XXL]) {
      setType(WidthTypeEnum.XXL);
      setIsMobile(false);
    } else if (width >= minWidthMap[WidthTypeEnum.XL]) {
      setType(WidthTypeEnum.XL);
      setIsMobile(false);
    } else if (width >= minWidthMap[WidthTypeEnum.LG]) {
      setType(WidthTypeEnum.LG);
      setIsMobile(false);
    } else if (width >= minWidthMap[WidthTypeEnum.MD]) {
      setType(WidthTypeEnum.MD);
      setIsMobile(false);
    } else if (width >= minWidthMap[WidthTypeEnum.SM]) {
      setType(WidthTypeEnum.SM);
      setIsMobile(true);
    } else {
      setType(WidthTypeEnum.XS);
      setIsMobile(true);
    }
  }

  useEventListener('resize', updateWidthType);

  useEffect(() => {
    updateWidthType();
  }, []);

  return {
    type,
    isMobile
  }
}
